<template>
  <section :class="$style.article">
    <el-form
      :class="$style.form"
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
    >
      <el-form-item label="Категория" prop="categoryId">
        <el-select
          :class="$style.categorySelect"
          v-model="form.categoryId"
          placeholder="Выберите"
          filterable
          remote
          :loading="categoriesLoading"
        >
          <el-option
            v-for="item in categories"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="Вопрос" prop="question">
        <el-input v-model="form.question"></el-input>
      </el-form-item>
      <el-form-item label="Ответ" prop="answer">
        <TextEditor
          :value.sync="form.answer"
          :is-image="false"
          :is-media="false"
        />
      </el-form-item>
      <el-button type="primary" @click="submitForm()"> Сохранить </el-button>
    </el-form>
  </section>
</template>

<script>
import delivery from '@/delivery'
import TextEditor from '@/components/atoms/TextEditor.vue'

export default {
  components: { TextEditor },
  data() {
    return {
      categories: [],
      form: {
        question: '',
        answer: '',
        categoryId: '',
      },
      rules: {
        categoryId: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
        question: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        answer: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'change',
          },
        ],
      },
    }
  },
  created() {
    this.getCategoriesList()
  },
  methods: {
    async submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const data = { ...this.form }

          const result = await delivery.ContentServiceCore.FAQsActions.create(
            data,
          )
          loading.close()

          if (!result.error) {
            this.$message({
              message: 'FAQ успешно создан',
              type: 'success',
            })
            this.$router.push('/addwine/content/faqs/faqs')
          }
        }
      })
    },
    async getCategoriesList() {
      this.categoriesLoading = true

      const query = {
        withPagination: 'false',
        withFAQs: 'false',
      }

      const { value, error } =
        await delivery.ContentServiceCore.FAQCategoriesActions.getList(query)
      if (error) return
      this.categories = value.data

      this.categoriesLoading = false
    },
  },
}
</script>

<style lang="scss" module>
.article {
  padding: 1rem;
  .form {
    & > div > label {
      text-align: left;
    }
    .categorySelect {
      width: 100%;
    }
  }
}
</style>
